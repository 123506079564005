<template>
    <main class="bd-main order-1">
        <div class="bd-intro ps-lg-4">
            <div class="d-md-flex align-items-center justify-content-between">
                <h1 class="bd-title" id="content">Games Admin</h1>
            </div>
        </div>
        <div class="bd-content ps-lg-4">
            <p>Load Game</p>
            <Form @submit="findGame">
                <div class="form-group">
                    <label for="gameid">Game ID/Game Name</label>
                    <Field name="gameid" v-model="data.gameid" type="text" class="form-control" :rules="isRequired" />
                </div>
                <error-message name="name"></error-message>
                <div class="form-group">
                    <button class="btn btn-primary btn-block" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Search</span>
                    </button>
                </div>
            </Form>
            <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
                {{ message }}
            </div>
            <div v-if="data.game">
                <h3>{{ this.data.game.name }}</h3>
                ID: {{ this.data.game._id }}
                Status: {{ this.data.game.status }}
                <Form @submit="updateOptions" class="row g-3">
                    <GameForm :form="form" @update:form="onFormUpdate" :ongoing="data.game.status !== 'open'" />
                    <div class="col-12">
                        <button class="btn btn-primary btn-block" :disabled="loading">
                            <span
                                v-show="loading"
                                class="spinner-border spinner-border-sm"
                            ></span>
                            <span>Save</span>
                        </button>
                    </div>
                </Form>
                <div class="col-md-4" v-if="!['end','open'].includes(data.game.status)">
                    <label for="inputStatus" class="form-label">Status</label>
                    <select id="inputMap" class="form-select" v-model="statusOverride">
                        <option>end manually</option>
                        <option>open</option>
                        <option>keep</option>
                    </select>
                    <button type="button" v-on:click="setStatus" class="btn btn-primary btn-danger btn-block">Set Status</button>
                </div>
                <div class="col-md-4" v-if="'open' === data.game.status">
                    <button type="button" v-on:click="startGame" class="btn btn-primary btn-danger btn-block">Recriar</button>
                </div>
            </div>
            <div v-if="data.games">
                <h3>Jogos</h3>
                <table class="table table-sm table-stripped table-hover" style="">
                    <thead class="thead-dark">
                        <tr>
                            <th>Id</th>
                            <th style="min-width: 100px">Nome</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="game in data.games" :key="game._id" @click="loadGame(game)">
                            <td>{{ game._id }}</td>
                            <td>{{ game.name }}</td>
                            <td>{{ game.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </main>

</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TMService from "../../services/tm.service";
import GameForm from "../ui/GameForm.vue";

export default {
    name: "GamesAdmin",
    components: {
        Form,
        Field,
        ErrorMessage,
        GameForm
    },
    data() {
        return {
            loading: false,
            data: {},
            message: "",
            section: 'GamesAdmin',
            statusOverride: '',
            form: {
                name: "",
                numPlayers: 4,
                prelude: false,
                venus: false,
                colonies: false,
                fanMade: false,
                turmoil: false,
                turmoilA: false,
                turmoilAV: "Chairman", 
                ptvis:true, 
                trajectory: false,
                moon: false,
                ares: false,
                maxRating: false,
                minLimit: Math.floor((this.$store.state.auth.user.openskill || 0) + 50),
                maxLimit: Math.ceil((this.$store.state.auth.user.openskill || 0) + 50),
                map: "Random All", 
                anon: false
            },
        };
    },
    mounted() {
        TMService.getSettings().then(
            (response) => {
                this.settings = response.data;
            },
            (error) => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    },
    computed: {
        checkRange(){
        if(!this.form.maxRating) return false;

        let userRating = (this.$store.state.auth.user.openskill || 0) + 50
        return this.form.maxLimit < userRating || this.form.minLimit > userRating || this.form.minLimit >= this.form.maxLimit ; 
        }
    },
    methods: {
        onFormUpdate(updatedForm) {
            // Only update the parent form if it differs
            if (JSON.stringify(updatedForm) !== JSON.stringify(this.form)) {
                this.form = updatedForm;
            }
        },
        findGame() {
            if (this.data.gameid) {
                this.loading = true;

                TMService.findGame(this.data.gameid).then(
                    (response) => {
                        if (response?.data && response.data.length == 1) {
                            this.loadGame(response.data[0]);
                        }
                        this.data.games = response.data;
                        this.loading = false;
                    },
                    (error) => {
                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        this.loading = false;
                    }

                );
            }
        },
        loadGame(game)
        {
            this.data.game = game;
            if(game.options)
            {
                let options = JSON.parse(game.options);
                for(var k in options) 
                    this.form[k] = options[k];
                this.form.name = game.name;
            }
        },
        setStatus()
        {
            TMService.setStatus(this.data.game._id, this.statusOverride).then(
                () => {
                    this.data.game.status = this.statusOverride;
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.loading = false;
                }
            );
        },
        startGame()
        {
            if(this.data.game.status === 'open')
            {
                TMService.startGame(this.data.game._id).then(
                    (response) => {
                        this.message = response.data.message;
                    },
                    (error) => {
                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        this.loading = false;
                    }
                );
            }
        },
        updateOptions()
        {
            TMService.updateGame(this.data.game._id, this.form).then(
                (response) => {
                    this.message = response.data.message;
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.loading = false;
                }
            );
        }
    },
};
</script>