<template>
    <main class="bd-main order-1">
        <div class="bd-intro ps-lg-4">
            <div class="d-md-flex align-items-center justify-content-between">
                <h1 class="bd-title" id="content">League</h1>
            </div>
        </div>
        <div class="bd-content ps-lg-4">
        <p>League Info</p>
        <div>
            <table class="table table-sm table-stripped table-hover" style="">
                <thead class="thead-dark">
                    <tr>
                        <th>League ID</th>
                        <th style="min-width: 100px">Nome</th>
                        <th>Season</th>
                        <th>Status</th>
                        <th>Options</th>
                        <th>Games</th>
                        <th>Round</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="league in leagues" :key="league._id">
                        <td>{{ league._id }}</td>
                        <td>{{ league.name }}</td>
                        <td>{{ league.season }}</td>
                        <td>{{ league.status }}</td>
                        <td><button type="button" v-on:click="setStatus" class="btn btn-link btn-sm btn-block">Show Options</button></td>
                        <td><button type="button" v-on:click="setStatus" class="btn btn-link btn-sm btn-block">Show Games</button></td>
                        <td>{{ league.round_id }}</td>
                        <td><button v-if="league.status === 'running'" type="button" v-on:click="startNextRound(league._id)" class="btn btn-primary btn-block">Start Next Round</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </main>
</template>

<script>
import LeagueService from "../../services/league.service";

export default {
  name: "League",
  data() {
    return {
      loading: false,
      leagues: []
    };
  },
  mounted() {
    LeagueService.getAllLeagues().then(
      (response) => {
        this.leagues = response?.data || [];
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    startNextRound(league_id){
        LeagueService.nextLeagueRound(league_id).then(
        (response) => {
            this.message = response.data.message;
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        );
    }
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .bd-layout {
    grid-template-columns: 1fr 5fr;
  }
}
@media (min-width: 768px) {
  .bd-layout {
      display: grid;
      gap: 1.5rem;
      grid-template-areas: "sidebar main";
      grid-template-columns: 1fr 3fr;
  }
}
aside {
    display: block;
}
.my-md-4 {
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important;
}

.bd-sidebar {
    grid-area: sidebar;
}

.bd-links {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    display: block !important;
    height: calc(100vh - 7rem);
    padding-left: .25rem;
    margin-left: -.25rem;
    overflow-y: auto;
}

.bd-main {
    grid-template-areas:
        "intro toc"
        "content toc";
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto 1fr;
}

.bd-main {
    grid-area: main;
}

.order-1 {
    order: 1!important;
}

.mb-1 {
    margin-bottom: .25rem!important;
}

.bd-links .btn {
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0,0,0,0.65);
    background-color: transparent;
    border: 0;
}

.rounded {
    border-radius: .25rem!important;
}

.d-inline-flex {
    display: inline-flex!important;
}

.bd-intro {
    grid-area: intro;
}

.bd-content {
    grid-area: content;
    min-width: 1px;
}

.ps-lg-4 {
    padding-left: 1.5rem!important;
}

.flex-md-row-reverse {
    flex-direction: row-reverse!important;
}

*, ::after, ::before {
    box-sizing: border-box;
}
</style>