<template>
  <main class="bd-main order-1">
      <div class="bd-intro ps-lg-4">
        <div class="d-md-flex align-items-center justify-content-between">
          <h1 class="bd-title" id="content">Settings</h1>
        </div>
      </div>
      <div class="bd-content ps-lg-4">
        <p>Change the server settings</p>
        <Form @submit="updateSettings">
          <div class="form-group">
            <label for="server">Server</label>
            <Field name="server" v-model="settings.server" type="text" class="form-control" />
          </div>

          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Save</span>
            </button>
          </div>
        </Form>
        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>
  </main>
  
</template>

<script>
import { Form, Field } from "vee-validate"; 
import TMService from "../../services/tm.service";

export default {
  name: "Settings",
  components: {
    Form,
    Field
  },
  data() {
    return {
      loading: false,
      settings: {},
      message: "",
      section: 'Settings'
    };
  },
  mounted() {
    TMService.getSettings().then(
      (response) => {
        this.settings = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    updateSettings() {
      this.loading = true;

      TMService.saveSettings(this.settings).then(
      (response) => {
        this.message = response.message;
        this.loading = false;
      },
      (error) => {
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        
        this.loading = false;
      }
    );
    },
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .bd-layout {
    grid-template-columns: 1fr 5fr;
  }
}
@media (min-width: 768px) {
  .bd-layout {
      display: grid;
      gap: 1.5rem;
      grid-template-areas: "sidebar main";
      grid-template-columns: 1fr 3fr;
  }
}
aside {
    display: block;
}
.my-md-4 {
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important;
}

.bd-sidebar {
    grid-area: sidebar;
}

.bd-links {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    display: block !important;
    height: calc(100vh - 7rem);
    padding-left: .25rem;
    margin-left: -.25rem;
    overflow-y: auto;
}

.bd-main {
    grid-template-areas:
        "intro toc"
        "content toc";
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto 1fr;
}

.bd-main {
    grid-area: main;
}

.order-1 {
    order: 1!important;
}

.mb-1 {
    margin-bottom: .25rem!important;
}

.bd-links .btn {
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0,0,0,0.65);
    background-color: transparent;
    border: 0;
}

.rounded {
    border-radius: .25rem!important;
}

.d-inline-flex {
    display: inline-flex!important;
}

.bd-intro {
    grid-area: intro;
}

.bd-content {
    grid-area: content;
    min-width: 1px;
}

.ps-lg-4 {
    padding-left: 1.5rem!important;
}

.flex-md-row-reverse {
    flex-direction: row-reverse!important;
}

*, ::after, ::before {
    box-sizing: border-box;
}
</style>