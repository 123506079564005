<template>
  <div class="container-xxl my-md-4 bd-layout">
    <aside class="bd-sidebar">
      <nav class="collapse bd-links" id="bd-docs-nav" aria-label="Docs navigation">
        <ul class="list-unstyled mb-0 py-3 pt-md-1">
          <li class="mb-1">
            <button class="btn d-inline-flex align-items-center rounded collapsed" @click="section='Settings'" >
              Settings
            </button>
          </li>
          <li class="mb-1">
            <button class="btn d-inline-flex align-items-center rounded collapsed" @click="section='League'">
              League
            </button>
          </li>
          <li class="mb-1">
            <button class="btn d-inline-flex align-items-center rounded collapsed" @click="section='GamesAdmin'">
              Games Management
            </button>
          </li>
        </ul>
      </nav>
    </aside>
    <Settings v-if="section=='Settings'" />
    <League v-else-if="section=='League'"/>
    <GamesAdmin v-else-if="section=='GamesAdmin'"/>
  </div>
  
</template>

<script> 
import League from "./admin/League.vue";
import Settings from "./admin/Settings.vue"; 
import GamesAdmin from "./admin/GamesAdmin.vue"; 

export default {
  name: "Admin",
  components: { 
    League,
    Settings,
    GamesAdmin
  },
  data() {
    return {
      section: 'Settings'
    };
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .bd-layout {
    grid-template-columns: 1fr 5fr;
  }
}
@media (min-width: 768px) {
  .bd-layout {
      display: grid;
      gap: 1.5rem;
      grid-template-areas: "sidebar main";
      grid-template-columns: 1fr 3fr;
  }
}
aside {
    display: block;
}
.my-md-4 {
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important;
}

.bd-sidebar {
    grid-area: sidebar;
}

.bd-links {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    display: block !important;
    height: calc(100vh - 7rem);
    padding-left: .25rem;
    margin-left: -.25rem;
    overflow-y: auto;
}

.bd-main {
    grid-template-areas:
        "intro toc"
        "content toc";
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto 1fr;
}

.bd-main {
    grid-area: main;
}

.order-1 {
    order: 1!important;
}

.mb-1 {
    margin-bottom: .25rem!important;
}

.bd-links .btn {
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0,0,0,0.65);
    background-color: transparent;
    border: 0;
}

.rounded {
    border-radius: .25rem!important;
}

.d-inline-flex {
    display: inline-flex!important;
}

.bd-intro {
    grid-area: intro;
}

.bd-content {
    grid-area: content;
    min-width: 1px;
}

.ps-lg-4 {
    padding-left: 1.5rem!important;
}

.flex-md-row-reverse {
    flex-direction: row-reverse!important;
}

*, ::after, ::before {
    box-sizing: border-box;
}
</style>